import styled from 'styled-components';

import { Person } from '@mui/icons-material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MailIcon from '@mui/icons-material/Mail';

export interface IDisabledProps {
  isDisabled: boolean;
}

export const ContactTitle = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 30px;
  line-height: 18px;
  padding: 15px 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.common.white};
`;

export const ContactSubtitle = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 14px;
  line-height: 18px;
  padding: 10px 0;
  text-align: center;
  color: ${(props) => props.theme.palette.common.white};
`;

export const ButtonDiv = styled.div<IDisabledProps>`
  background-color: ${(props) => (props.isDisabled ? '#122C48' : props.theme.palette.common.green)};
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  width: 234px;
  height: 50px;
`;

export const SubmitDiv = styled.div`
  width: 100%;
  display: flex;
  padding: 15px 0 25px 0;
  justify-content: center;
  align-items: center;
`;

export const ContactForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const PersonIcon = styled(Person)`
  color: ${(props) => props.theme.palette.secondary};
`;

export const Mail = styled(MailIcon)`
  color: ${(props) => props.theme.palette.secondary};
`;

export const Appartment = styled(ApartmentIcon)`
  color: ${(props) => props.theme.palette.secondary};
`;

export const WrapperInput = styled.div`
  width: 100%;
  margin: 10px 0;
`;

import styled from 'styled-components';

import { SIZES } from '@style/sizes';

export const Wrapper = styled.div`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    padding-top: 40px;
  }
  width: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export const WrapperFirstModule = styled.div`
  @media (max-width: ${SIZES.TABLET_800}px) {
    padding: 60px 20px;
  }
  padding: 60px 80px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.palette.quinternary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WrapperTitle = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const WrapperSubtitle = styled.div`
  @media (max-width: ${SIZES.TABLET_768}px) {
    padding: 0 20px;
  }
  padding: 0 90px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
export const Title = styled.h1`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    font-size: 36px;
    line-height: 42px;
  }
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 48px;
  line-height: 55px;
  text-align: center;
  color: ${(props) => props.theme.palette.primary};
`;

export const Subtitle = styled.p`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    font-size: 18px;
    line-height: 24px;
  }
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.septernary};
  text-align: center;
`;

export const ContactUs = styled.button`
  width: 236px;
  height: 50px;
  margin-top: 40px;
  background-color: ${(props) => props.theme.palette.secondary};
  color: ${(props) => props.theme.palette.common.white};
  border: none;
  border-radius: 10px;
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  &:hover {
    background-color: #1d2d3d;
  }
`;

export const WrapperText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.septernary};
`;

export const WrapperContent = styled.div`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    flex-direction: column;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const WrapperValue = styled.div`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    margin: 0;
    padding-top: 30px;
    &:nth-child(1) {
      margin-top: 30px;
    }
  }
  width: 100%;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const WrapperImage = styled.div`
  max-width: 110px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Image = styled.img`
  width: 100%;
`;

export const SubtitleText = styled.h2`
  @media (max-width: ${SIZES.TABLET_740}px) {
    padding-top: 15px;
  }
  @media (max-width: ${SIZES.MOBILE_575}px) {
    padding-top: 30px;
    font-size: 22px;
  }

  text-align: center;
  margin-bottom: 40px;
  font-family: ${(props) => props.theme.fontFamilies.europa.bold};
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.septernary};
`;

export const TitleValue = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.europa.bold};
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.common.grey};
  text-align: center;
`;

export const DescriptionValue = styled.p`
  width: 100%;
  height: 84px;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.septernary};
  text-align: center;
`;

export const WrapperTitleBiography = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const TitleBioGraphy = styled.h3`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    text-align: left;
  }
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.primary};
  text-align: center;
`;

export const WrapperFounder = styled.div`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    flex-direction: row;
  }
  width: 100%;
  max-height: 254px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const WrapperTextBiography = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const WrapperContactUs = styled.div`
  width: 100%;
  padding: 60px 0px;
  background-color: ${(props) => props.theme.palette.tertiary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TitleContactUs = styled.h4`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    font-size: 24px;
    padding: 0 20px;
  }
  text-align: center;
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 24px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.primary};
`;

export const WrapperLastText = styled.div`
  padding: 30px 0 60px;
`;

export const LastText = styled.h5`
  text-align: center;
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 24px;
  max-width: 650px;
  line-height: 34px;
  color: ${(props) => props.theme.palette.septernary};
`;

export const LastTextBold = styled.h5`
  display: contents;
  font-size: 24px;
  line-height: 34px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.europa.bold};
`;

export const WrapperBiography = styled.div`
  @media (max-width: ${SIZES.TABLET_800}px) {
    padding: 0 35px;
  }
  @media (max-width: ${SIZES.MOBILE_575}px) {
    padding: 0 20px;
  }
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

export const FoundersImageWrapper = styled.div`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    height: 600px;
    margin-bottom: 20px;
  }
  width: 100%;
  height: 350px;
  margin-bottom: 80px;
  display: flex;
  position: relative;
`;

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

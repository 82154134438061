import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ChangeEvent, useState } from 'react';
import { validateEmail } from 'validators/email';

import TextButton from '@components/TextButton';
import TextInput from '@components/TextInput';

import { PATHS } from '@global/urls';
import { hasWindow } from '@helpers/window';

import {
  Appartment,
  ButtonDiv,
  ContactForm,
  ContactSubtitle,
  ContactTitle,
  Mail,
  PersonIcon,
  SubmitDiv,
  WrapperInput,
} from './styles.ContactUsForm';
import { IContactUsFormProps } from './types.ContactUsForm';

const ContactUsForm = (props: IContactUsFormProps) => {
  const { t } = useTranslation();
  const { loading, onSubmit } = props;
  const [userDetails, setUserDetails] = useState({
    userName: '',
    userEmail: '',
    userCompany: '',
    message: '',
  });
  const [emailError, setEmailError] = useState('');

  const handleNameChange = (value: string) => {
    setUserDetails({ ...userDetails, userName: value });
  };

  const handleEmailChange = (value: string) => {
    setUserDetails({ ...userDetails, userEmail: value });
  };

  const handleCompanyChange = (value: string) => {
    setUserDetails({ ...userDetails, userCompany: value });
  };

  const handleMessageChange = (value: string) => {
    setUserDetails({ ...userDetails, message: value });
  };

  const handleSubmit = () => {
    if (!validateEmail(userDetails.userEmail)) {
      setEmailError('Incorrect email format');
    } else {
      onSubmit(userDetails);
    }
  };
  return (
    <ContactForm>
      <ContactTitle>{t('CONTACTUSFORM:TITLE')}</ContactTitle>
      <ContactSubtitle>{t('CONTACTUSFORM:SUBTITLE')}</ContactSubtitle>
      <WrapperInput>
        <TextInput
          onChange={handleNameChange}
          value={userDetails.userName}
          placeholder="Name"
          adornment={<PersonIcon />}
          isDisabled={false}
          error={''}
          id={'contactUs-name'}
          name={'contactUs-name'}
        />
      </WrapperInput>
      <WrapperInput>
        <TextInput
          onChange={handleEmailChange}
          value={userDetails.userEmail}
          placeholder="Email"
          adornment={<Mail />}
          isDisabled={false}
          error={emailError}
          id={'contactUs-email'}
          name={'contactUs-email'}
        />
      </WrapperInput>
      <WrapperInput>
        <TextInput
          onChange={handleCompanyChange}
          value={userDetails.userCompany}
          placeholder="Company Name"
          adornment={<Appartment />}
          isDisabled={false}
          error={''}
          id={'contactUs-company'}
          name={'contactUs-company'}
        />
      </WrapperInput>
      <WrapperInput>
        <TextInput
          id={'contactUs-message'}
          name={'contactUs-message'}
          onChange={handleMessageChange}
          multiline
          rows={5}
          value={userDetails.message}
          placeholder="Message"
          isDisabled={false}
          error={''}
        />
      </WrapperInput>
      <SubmitDiv>
        <ButtonDiv
          isDisabled={
            userDetails.userName === '' ||
            userDetails.userEmail === '' ||
            userDetails.userCompany === '' ||
            userDetails.message === ''
          }
        >
          <TextButton
            isDisabled={
              userDetails.userName === '' ||
              userDetails.userEmail === '' ||
              userDetails.userCompany === '' ||
              userDetails.message === ''
            }
            isLoading={loading}
            text={t('CONTACTUSFORM:BUTTON_COPY')}
            onSubmit={handleSubmit}
          />
        </ButtonDiv>
      </SubmitDiv>
    </ContactForm>
  );
};

export default ContactUsForm;

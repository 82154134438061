import Lottie, { AnimationItem } from 'lottie-web';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { LottieAnimationContainer } from './styles';
import { IProps } from './types';

const LottiePlayer: FunctionComponent<IProps> = (props: IProps) => {
  const animationRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimations] = useState<AnimationItem>({} as AnimationItem);

  useEffect(() => {
    if (animationRef.current) {
      const lottie: AnimationItem = Lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        autoplay: props.autoplay,
        loop: props.loop,
        animationData: props.animationJsonPath,
        name: props.name ? props.name : `lottieAnimation`,
        rendererSettings: {
          preserveAspectRatio: props.preserveAspectRatio
            ? props.preserveAspectRatio
            : 'xMidYMid meet',
        },
        assetsPath: props.assetPath,
      });
      setAnimations(lottie);
      if (props.renderFinish) {
        props.renderFinish(true);
      }
    }
  }, [
    animationRef,
    props.animationJsonPath,
    props.name,
    props.loop,
    props.autoplay,
    props.preserveAspectRatio,
    props.assetPath,
  ]);

  if (!props.still && animation.name) {
    animation.play();
    let enteredFrame = false;
    animation.addEventListener('enterFrame', () => {
      const currentSecond = animation.currentFrame / animation.frameRate;
      if (!enteredFrame && Math.floor(currentSecond) === 1 && props.setAnimationStarted) {
        props.setAnimationStarted();
        enteredFrame = true;
      }
    });
    animation.setSpeed(props.speed ? props.speed : 1);
  }

  useEffect(() => {
    if (props.still && animation.name) {
      animation.goToAndStop(1);
    }
  }, [props.still, animation]);

  useEffect(() => {
    if (props.segments && animation.name) {
      animation.playSegments(props.segments, false);
    }
  }, [animation, props.segments]);

  const { onComplete } = props;
  useEffect(() => {
    if (onComplete && animation.name) {
      animation.addEventListener('complete', () => {
        if (onComplete) {
          onComplete();
        }
      });
    }
  }, [animation, onComplete]);

  return <LottieAnimationContainer width={props.width} ref={animationRef} />;
};

export default LottiePlayer;

export const addButtonFunctionality = (buttonId: string, redirectLink: string) => {
  const button = document.querySelectorAll(`#${buttonId} path`)[0] as HTMLElement;
  if (button) {
    button.addEventListener('mouseover', () => {
      button.style.cursor = 'pointer';
    });
    button.addEventListener('mouseleave', () => {
      button.style.cursor = 'default';
    });
    const openCTA = () => {
      window.open(redirectLink, '_blank');
    };

    button.addEventListener('click', openCTA);
  }
};

import { graphql } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AboutUs from '@components/AboutUs';
import { ContactUsDetails } from '@components/ContactUsForm/types.ContactUsForm';
import Layout from '@components/Layout';
import UserMessage from '@components/Layout/UserMessage';
import SEO from '@components/SEO';

import { PATHS } from '@global/urls';
import { post } from '@helpers/api';
import { UserMessageType } from '@helpers/enum/UserMessageTypes';
import { getImageData, ParseImages } from '@helpers/imageHelper';
import { hasWindow, trackEventAnalytics } from '@helpers/window';

import consumer from './assets/consumers.svg';
import employees from './assets/employees.svg';
import investors from './assets/investors.svg';

function AboutUsPage({ data }: any) {
  const images = ParseImages(data);
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [userMessageType, setUserMessageType] = useState(UserMessageType.SUCCESS);

  const onSubmit = async (userDetails: ContactUsDetails) => {
    try {
      setLoading(true);
      await post('users/contact-us', { ...userDetails, isMarketingEmail: true });
      setLoading(false);
      setOpenModal(false);
      setUserMessageType(UserMessageType.SUCCESS);
      setUserMessage('The email was sent correctly');
    } catch (error) {
      setLoading(false);
      setOpenModal(false);
      setUserMessageType(UserMessageType.FAIL);
      setUserMessage('There was a problem sending your email, please try again');
    }
  };
  const firstModuleValues = [
    {
      title: t('ABOUTUS:SECTION_ONE_CONSUMER'),
      description: t('ABOUTUS:SECTION_ONE_CONSUMER_DESCRIPTION'),
      image: consumer,
    },
    {
      title: t('ABOUTUS:SECTION_ONE_INVESTOR'),
      description: t('ABOUTUS:SECTION_ONE_INVESTOR_DESCRIPTION'),
      image: investors,
    },
    {
      title: t('ABOUTUS:SECTION_ONE_EMPLOYEES'),
      description: t('ABOUTUS:SECTION_ONE_EMPLOYEES_DESCRIPTION'),
      image: employees,
    },
  ];

  useEffect(() => {
    if (userMessage !== '') {
      if (hasWindow()) {
        window.scrollTo(0, 0);
      }
      setTimeout(() => {
        setUserMessage('');
      }, 3000);
    }
  }, [userMessage]);

  return (
    <Layout footerLogo={getImageData(images, 'footer')} currentRoute={PATHS.ABOUT_US}>
      <SEO metaDescription={t('SEO:METADESCRIPTION')} metaTitle={t('SEO:METATITLE')} />
      {userMessage !== '' && <UserMessage message={userMessage} messageType={userMessageType} />}
      <AboutUs
        firstModuleValues={firstModuleValues}
        loading={loading}
        onSubmit={onSubmit}
        handleOpenMoal={() => {
          trackEventAnalytics(
            !openModal ? 'Open Demo Modal - About Us' : 'Close Demo Modal - About Us',
            {
              route: PATHS.SOLUTION,
            },
          );
          setOpenModal(!openModal);
        }}
        openModal={openModal}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($language: String!) {
    allFile(
      filter: {
        relativeDirectory: {
          in: ["components/Layout/Footer/assets", "pages/aboutUs/animation/desktop/images"]
        }
      }
    ) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    locales: allLocale(
      filter: {
        ns: { in: ["topbar", "footer", "demoModal", "aboutUs", "contactUs", "SEO"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AboutUsPage;

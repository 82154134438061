import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import ContactUsForm from '@components/ContactUsForm';
import { SectionContainer } from '@components/Layout/Layout.styles';
import LottiePlayer from '@components/LottiePlayer';
import Modal from '@components/Modal';

import { addButtonFunctionality } from '@helpers/lottie';
import { hasWindow } from '@helpers/window';
import { SIZES } from '@style/sizes';

import AnimationPathDesktop from './animation/desktop/Motive_about-us-animation.json';
import AnimationPathMobile from './animation/mobile/Motive_about-us-animation.json';
import {
  ContactUs,
  Subtitle,
  Title,
  Wrapper,
  WrapperFirstModule,
  WrapperSubtitle,
  WrapperText,
  WrapperTitle,
  Text,
  WrapperContent,
  WrapperValue,
  WrapperImage,
  Image,
  LastText,
  TitleValue,
  DescriptionValue,
  WrapperTitleBiography,
  TitleBioGraphy,
  WrapperTextBiography,
  WrapperContactUs,
  TitleContactUs,
  SubtitleText,
  LastTextBold,
  WrapperLastText,
  WrapperBiography,
  FoundersImageWrapper,
  WrapperButton,
} from './styles';
import { IAboutUsProps } from './types';

const AboutUs = (props: IAboutUsProps) => {
  const { firstModuleValues, loading, onSubmit, openModal, handleOpenMoal } = props;
  const [renderFinish, setRenderFinish] = useState(false);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: `(max-width: ${SIZES.MOBILE_575}px)`,
  });

  useEffect(() => {
    if (hasWindow() && renderFinish) {
      addButtonFunctionality('s01_0bj02_c01_in', 'https://www.linkedin.com/in/kaigray/');
      addButtonFunctionality('s01_0bj01_c01_in', 'https://www.linkedin.com/in/dr-taylor-gray/');
    }
  }, [renderFinish]);

  return (
    <Wrapper>
      <Modal open={openModal} width={500} onClose={handleOpenMoal}>
        <ContactUsForm loading={loading} onSubmit={onSubmit} />
      </Modal>
      <SectionContainer>
        <WrapperTitle>
          <Title>{t('ABOUTUS:TITLE')}</Title>
        </WrapperTitle>
        <WrapperSubtitle>
          <Subtitle>{t('ABOUTUS:SUBTITLE')}</Subtitle>
        </WrapperSubtitle>
        <WrapperFirstModule>
          <WrapperText>
            <Text>{t('ABOUTUS:SECTION_ONE_TEXT_ONE')}</Text>
          </WrapperText>
          <WrapperContent>
            {firstModuleValues.map((values) => (
              <WrapperValue>
                <WrapperImage>
                  <Image src={values.image} />
                </WrapperImage>
                <TitleValue>{values.title}</TitleValue>
                <DescriptionValue>{values.description}</DescriptionValue>
              </WrapperValue>
            ))}
          </WrapperContent>
          <SubtitleText> {t('ABOUTUS:SECTION_ONE_SUBTITLE')}</SubtitleText>
          <WrapperText>
            <Text>{t('ABOUTUS:SECTION_ONE_TEXT_TWO')}</Text>
          </WrapperText>
        </WrapperFirstModule>
        <WrapperBiography>
          <WrapperTitleBiography>
            <TitleBioGraphy>{t('ABOUTUS:SECTION_FOUNDED_TITLE')}</TitleBioGraphy>
          </WrapperTitleBiography>
          <WrapperTextBiography>
            <Text>{t('ABOUTUS:SECTION_FOUNDED_DESCRIPTION')}</Text>
          </WrapperTextBiography>
          <FoundersImageWrapper>
            {isMobile && (
              <LottiePlayer
                animationJsonPath={AnimationPathMobile}
                assetPath="/lottie-images-aboutUs/"
                loop
                autoplay
                renderFinish={(value: boolean) => {
                  setRenderFinish(value);
                }}
              />
            )}
            {!isMobile && (
              <LottiePlayer
                animationJsonPath={AnimationPathDesktop}
                assetPath="/lottie-images-aboutUs/"
                loop
                autoplay
                renderFinish={(value: boolean) => {
                  setRenderFinish(value);
                }}
              />
            )}
          </FoundersImageWrapper>
          <WrapperTextBiography>
            <Text>{t('ABOUTUS:SECTION_FOUNDED_HISTORY')}</Text>
          </WrapperTextBiography>
          <WrapperLastText>
            <LastText>
              {t('ABOUTUS:SECTION_LAST_TEXT_ONE')}{' '}
              <LastTextBold>{t('ABOUTUS:SECTION_FOUNDED_KAI_NAME')} </LastTextBold>
              {t('ABOUTUS:SECTION_LAST_TEXT_TWO')}{' '}
              <LastTextBold>{t('ABOUTUS:SECTION_FOUNDED_TAYLOR_NAME')} </LastTextBold>
              {t('ABOUTUS:SECTION_LAST_TEXT_THREE')}{' '}
              <LastTextBold>{t('ABOUTUS:SECTION_LAST_TEXT_FOUR')} </LastTextBold>
              {t('ABOUTUS:SECTION_LAST_TEXT_FIVE')}
            </LastText>
          </WrapperLastText>
        </WrapperBiography>
      </SectionContainer>
      <WrapperContactUs>
        <SectionContainer>
          <TitleContactUs>{t('ABOUTUS:CONTACT_US_TITLE')}</TitleContactUs>
          <WrapperButton>
            <ContactUs onClick={handleOpenMoal}>{t('ABOUTUS:CONTACT_US_COPY_BUTTON')}</ContactUs>
          </WrapperButton>
        </SectionContainer>
      </WrapperContactUs>
    </Wrapper>
  );
};

export default AboutUs;
